import Bathrooms from './bathrooms.svg';
import Rooms from './bedrooms.svg';
import Size from './squaring.svg';
import MapSvg from './map.svg';
import PriceForSquare from './squaring_price.svg';
import Stairs from './stairs.svg';

import Brick from './brick.svg';
import Epk from './epk.svg';
import Panel from './panel.svg';

import Garage from './garage.svg';
import Parking from './parking.svg';

import Checkmark from './checkmark.svg';
import MapTarget from './map_target.svg';

export default {
  bathrooms: Bathrooms,
  rooms: Rooms,
  size: Size,
  squarePrice: PriceForSquare,
  map: MapSvg,
  stairs: Stairs,
  brick: Brick,
  epk: Epk,
  panel: Panel,
  garage: Garage,
  parking: Parking,
  checkmark: Checkmark,
  mapTarget: MapTarget,
};
