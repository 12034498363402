export const getConstructionTypeIcon = (type) => {
  switch (type) {
    case 'Панел':
      return 'panel';
    case 'Тухла':
      return 'brick';
    case 'ЕПК':
      return 'epk';
    case 'ПК':
      return 'epk';
    case 'Гредоред':
      return;
    default:
      return false;
  }
};

export const getFeatureIcon = (type) => {
  switch (type) {
    case 'Паркинг':
      return 'parking';
    default:
      return 'checkmark';
  }
};

export const mainFeatures = ['WithParking'];

export const thousandsSeparator = (num) => {
  let num_parts = num.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return num_parts.join('.');
};
