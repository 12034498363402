import React from 'react';
import iconPath from './assets';

const Icon = ({ icon, size = 'md', ...props }) => {
  const path = iconPath[icon];
  const sizes = {
    xs: '14px',
    sm: '20px',
    md: '30px',
    lg: '50px',
  };
  return <img src={path} alt={icon || undefined} height={sizes[size]}></img>;
};

export default Icon;
