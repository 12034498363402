export const selectGolden = (state) => state.property.golden;

export const selectSilver = (state) => state.property.silver;

export const selectBuildings = (state) => state.property.buildings;

export const selectPropertyCategories = (state) =>
  state.property.propertyCategories;

export const selectBuildingCategories = (state) =>
  state.property.buildingCategories;

export const selectProperties = (state) => state.property.properties;

export const selectStaticMap = (state) => state.property.staticMap;

export const selectPlaceCoords = (state) => state.property.placeCoordinates;

export const selectPropertyParameters = (state) => state.property.parameters;

export const selectPropertyMandatoryParams = (state) =>
  state.property.mandatoryParameters;

export const selectPropertyParametersSpecific = (state) =>
  state.property.parametersSpecific.parameters;

export const selectPropertyFeatures = (state) =>
  state.property.parametersSpecific.features;

export const selectPropertySpecifics = (state) =>
  state.property.parametersSpecific;

export const selectProperty = (state) => state.property.property;

export const selectIsPropertyCleanedUp = (state) =>
  state.property.isPropertyCleanedUp;

export const selectLocalErrors = (state) => state.property.localErrors;

export const selectParamByResource = (
  state,
  resource,
  parameterOptionsCriteria = null
) => {
  const found = state.property.parameters.find(
    (parameter) => parameter.name === resource
  );

  if (parameterOptionsCriteria && found) {
    const enabled = state.property.parametersSpecific[parameterOptionsCriteria];
    return {
      ...found,
      items: found.items.filter((x) => enabled.includes(x.id)),
    };
  }

  return found;
};

export const selectCityCoords = (state) => state.property.cityCoords;

export const selectDistricts = (state) => state.property.districts;
export const selectCities = (state) => state.property.cities;
export const selectNeighborhoods = (state) => state.property.neighborhoods;

export const selectPropertyContacts = (state) =>
  state.property.propertyContacts;
